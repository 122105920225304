<template>
  <div>
    <WsUpdate
      ref="WsCreate"
      :id="_id"
      :modelName="model.tulpa_page_template.modelName"
      :label="model.tulpa_page_template.label"
      :fields="model.tulpa_page_template.fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      v-model="state"
      :emitSubmit="true"
      :emitInput="true"
      titleKey="name"
      @submit="$_onSubmit()"
      @modelDataInit="$_onModelDataInit($event)"
    >
    </WsUpdate>
    <WsMain>
      <WsStateTulpaSections v-model="state.content"></WsStateTulpaSections>
    </WsMain>
  </div>
</template>

<script>
import tulpa_page_template from "@/__vue2stone_cms/models/tulpa_page_template";
import S_TulpaPageTemplate from "@/__vue2stone_cms/service/api/v1/tulpa_page_template";
export default {
  metaInfo() {
    return {
      title: `${this.$t("edit")}${this.$t("Page Template")}`,
    };
  },

  data: () => ({
    updateData: null,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "項目設定",
              titleInLocale: true,
              fields: ["tags", "remark"],
            },
          ],
        },
      ],
    },
    model: {
      tulpa_page_template: tulpa_page_template,
    },
    state: {
      content: {},
    },
  }),

  methods: {
    $_onModelDataInit($event) {
      this.$_stateContentInit($event);
    },
    $_stateContentInit(modelData) {
      this.state.content = {};
      const content = modelData.content;
      const tulpa_sections = modelData.tulpa_sections;
      for (const key in content) {
        const contentItem = content[key];
        const _tulpaSection = tulpa_sections.find((e) => {
          return e.id == contentItem.id;
        });
        this.state.content[key] = {
          id: contentItem.id,
          content: contentItem.content,
          name: _tulpaSection.name,
          fields: _tulpaSection.fields,
          defaultContent: _tulpaSection.content,
        };
      }
    },
    async $_onSubmit() {
      const postData = JSON.parse(JSON.stringify(this.state));
      const _content = {};
      const _tulpa_sections = [];
      for (const key in this.state.content) {
        const contentItem = this.state.content[key];
        _content[key] = {
          id: contentItem.id,
          content: contentItem.content,
        };
        _tulpa_sections.push(contentItem.id);
      }
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(_content)))
      );
      postData.tulpa_sections = _tulpa_sections;
      try {
        this.$refs.WsCreate.loadingStart();
        await S_TulpaPageTemplate.patch(this._id, postData);
        this.$router.push("/tulpa_page_template");
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.WsCreate.loadingStop();
      }
    },
  },

  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>